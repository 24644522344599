const HS_FORMS_DOMAIN = 'hsforms';
const HS_DOMAINS = {
  hsforms: {
    prod: 'hsforms.com',
    qa: 'hsformsqa.com'
  }
};
export const SHARE_URL_TYPE = 'share';
export const SURVEY_URL_TYPE = 'survey';
export const HS_SHARE_URL_TYPES = {
  [SHARE_URL_TYPE]: 'share',
  [SURVEY_URL_TYPE]: 'survey'
};
export const getHubletSuffix = hublet => {
  /* eslint-disable-next-line hubspot-dev/no-hublet-comparison, hubspot-dev/no-hublet-references */
  return hublet && hublet !== 'na1' ? `-${hublet}` : '';
};
export const getDomainEnv = env => env === 'local' || env === 'qa' ? 'qa' : 'prod';
export const getSubdomain = shareType => HS_SHARE_URL_TYPES[shareType];
export const getDomain = (domain, env) => HS_DOMAINS[domain][getDomainEnv(env)];
export const getShareableUrl = (sharableUrlType, customOptions = {}) => {
  const options = Object.assign({
    hublet: '',
    isQA: false
  }, customOptions);
  const env = options.isQA ? 'qa' : 'prod';
  const subdomain = getSubdomain(sharableUrlType);
  const hubletSuffix = getHubletSuffix(options.hublet);
  const domain = getDomain(HS_FORMS_DOMAIN, env);
  return `https://${subdomain}${hubletSuffix}.${domain}`;
};