import {
  decodeShareableLink,
  getShareableLink,
} from 'forms-utils-lib/utils/ShareLink';

const PORTAL_AND_FORM_PATH_REGEX = /^[^/]+\/\/[^/]+\/([0-9]+)\/([a-z0-9-]+)/i;
const TEST_URL_PATH = 'test-share';

export function isQA() {
  return /(hubspot|hsforms)(qa).com/.test(window.location.href);
}

export function isHubSpotPage() {
  try {
    // Handles case where built on blazar
    if (/(test.html|test-share)/.test(window.location.href)) {
      return false;
    }

    return /(hubspot|hsforms)(qa)?.com/.test(window.location.href);
  } catch (error) {
    return false;
  }
}

export function getIsFormPortalUrl(url) {
  return PORTAL_AND_FORM_PATH_REGEX.test(url);
}

export function isFormAndPortalUrl() {
  return getIsFormPortalUrl(window.location.href);
}

export function getFormAndPortalUrl(data) {
  return window.location.origin + '/' + data.portalId + '/' + data.formGuid;
}

export function getHubletFromUrl() {
  const subdomain = window.location.hostname.split('.')[0];
  const hublet = subdomain.split('-')[1];
  return hublet || '';
}

export function parseFormAndPortalFromUrl(url) {
  const parseUrl = PORTAL_AND_FORM_PATH_REGEX.exec(url);
  const data = { portalId: null, formGuid: null };

  if (parseUrl && parseUrl[1] && parseUrl[2]) {
    data.portalId = parseInt(parseUrl[1], 10);
    data.formGuid = parseUrl[2];
  }

  return data;
}

export function getUrl() {
  const url = window.location.href;
  const updatedUrl = url.replace('/' + TEST_URL_PATH, '');
  return updatedUrl;
}

export function getDataFromUrl() {
  const pathData = isFormAndPortalUrl()
    ? parseFormAndPortalFromUrl(window.location.href)
    : decodeShareableLink(getUrl());

  pathData.region = getHubletFromUrl();

  return pathData;
}

export function getShareableUrl() {
  const data = getDataFromUrl();
  return getShareableLink(data.formGuid, data.portalId, isQA(), data.region);
}

export function getCanonicalUrl() {
  if (!isHubSpotPage()) {
    return window.location.href;
  }

  return getShareableUrl();
}
