import { getCanonicalUrl, getShareableUrl } from './utils/url';

export const getHsq = () => {
  // @ts-expect-error
  return window._hsq;
};

export const getHubSpotForms = () => {
  // @ts-expect-error
  return window.HubSpotForms;
};

export const getHsFormsOnReady = () => {
  // @ts-expect-error
  return window.hsFormsOnReady;
};

(function (formsReadyQueue) {
  function listenForFormReady() {
    getHsq().push(['setCanonicalUrl', getCanonicalUrl()]);

    getHubSpotForms().onFormReady(function () {
      getHubSpotForms().currentForm.setContext({
        pageUrl: getShareableUrl() + '' + window.location.search,
      });

      document.querySelector('.container').classList.add('loaded');
    });
    getHubSpotForms().onRenderError(function () {
      document.querySelector('.container').classList.add('error');
    });
  }

  if (getHubSpotForms()) {
    listenForFormReady();
  }
  formsReadyQueue.push(listenForFormReady);
})(getHsFormsOnReady());
