import { SHARE_URL_TYPE, SURVEY_URL_TYPE, getShareableUrl } from './ShareableUrlBuilder';
const VERSION = 1;
const LEGACY_UUID_IDENTIFIER = '-';
const BYTE_TO_HEX_MAP = [...Array(256)].map((v, i) => (i + 0x100).toString(16).substr(1));
const ENCODED_URL_PART_REGEX = /\/([a-z0-9-_]+)([?#].*|\/)?$/i;
const VALID_UUID_CHECK = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
const BASE64_URL_SAFE_MAP = [['+', '-'], ['/', '_']];
const bytesToUuid = (buf, offset) => {
  let i = offset || 0;
  return [BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], '-', BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], '-', BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], '-', BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], '-', BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]], BYTE_TO_HEX_MAP[buf[i++]]].join('');
};
const uuidToBytes = uuid => {
  // Assuming valid UUID
  const bytes = [];
  //@ts-expect-error o overload matches this call.
  uuid.replace(/[a-fA-F0-9]{2}/g, hex => {
    bytes.push(parseInt(hex, 16));
  });
  return bytes;
};
const arrayBufferToBase64 = buffer => {
  const bytes = new Uint8Array(buffer);
  return window.btoa([...Array(bytes.byteLength)].reduce((t, v, i) => t + String.fromCharCode(bytes[i]), ''));
};
const base64ToArrayBuffer = base64 => {
  const binaryString = window.atob(base64);
  return new Uint8Array([...Array(binaryString.length)].map((v, i) => binaryString.charCodeAt(i)));
};
const uuidToB64 = uuid => {
  return arrayBufferToBase64(uuidToBytes(uuid));
};
const B64ToUuid = b64 => {
  return bytesToUuid(base64ToArrayBuffer(b64));
};
const escapeBase64 = b64 => {
  return BASE64_URL_SAFE_MAP.reduce((t, v) => t.replace(new RegExp(`\\${v[0]}`, 'g'), v[1]), b64);
};
const unescapeBase64 = b64 => {
  return BASE64_URL_SAFE_MAP.reduce((t, v) => t.replace(new RegExp(`\\${v[1]}`, 'g'), v[0]), b64);
};
const isValidUuid = token => {
  return VALID_UUID_CHECK.test(token);
};
const getVersionFromSymbol = symbol => {
  return parseInt(symbol, 36) + (
  // @ts-expect-error The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
  isNaN(symbol * 1) && symbol === symbol.toUpperCase() ? 26 : 0);
};
const getCurrentVersionSymbol = () => {
  return VERSION < 36 ? VERSION.toString(36) : (VERSION - 26).toString(36).toUpperCase();
};
export const extractShareToken = shareableLink => {
  const regex = ENCODED_URL_PART_REGEX.exec(shareableLink);
  if (regex && regex.length) {
    return regex[1];
  }
  return null;
};
export const decode = encodedString => {
  const legacyUuid = encodedString.charAt(0) === LEGACY_UUID_IDENTIFIER;
  const offset = legacyUuid ? 2 : 1;
  const formGuid = B64ToUuid(unescapeBase64(encodedString.substring(offset, 22 + offset)));
  return {
    formGuid: legacyUuid ? formGuid.replace(/-/g, '') : formGuid,
    portalId: parseInt(encodedString.substring(22 + offset), 36),
    version: getVersionFromSymbol(encodedString.charAt(offset - 1))
  };
};
export const decodeShareableLink = shareableLink => {
  const extractedToken = extractShareToken(shareableLink);
  if (extractedToken) {
    return decode(extractedToken);
  }
  return null;
};
export const encode = (formGuid, portalId) => {
  return `${isValidUuid(formGuid) ? '' : LEGACY_UUID_IDENTIFIER}${getCurrentVersionSymbol()}${escapeBase64(uuidToB64(formGuid.replace(/[^a-fA-F0-9]/g, '')).substring(0, 22))}${portalId.toString(36)}`;
};
export const getShareableLink = (formGuid, portalId, isQA = false, hublet) => {
  return `${getShareableUrl(SHARE_URL_TYPE, {
    hublet,
    isQA
  })}/${encode(formGuid, portalId)}`;
};
export const getSurveyLink = (formGuid, portalId, isQA = false, hublet = '') => {
  return `${getShareableUrl(SURVEY_URL_TYPE, {
    hublet,
    isQA
  })}/${encode(formGuid, portalId)}`;
};